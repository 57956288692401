import * as React from 'react';
import { __ } from '../../helpers/TranslationService';
import styled from '@emotion/styled';
import {
    CheckboxLabel,
    CheckboxStyled,
    CheckboxWrapper,
    FilterBox,
    FilterBoxHeading,
    InfoDialogStyled,
    InfoIconStyled,
    InfoIconWrapper,
} from './styles';
import { DialogHeader, DialogHeaderTitle, DialogContent } from '../Dialog/Dialog';

const DialogDescription = styled.div`
    font-size: 14px;
    line-height: 22px;
`;

interface IProps {
    showOnlyAllInclusive: boolean;
    onAllInclusiveChange(param: boolean): void;
}

export const PopularFilter: React.FC<IProps> = (props) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    return (
        <>
            <FilterBox>
                <FilterBoxHeading>{__('popularServices', 'dreamlines')}</FilterBoxHeading>

                <CheckboxWrapper key="allInclusive">
                    <CheckboxStyled
                        id="allInclusive"
                        name="showOnlyAllInclusive"
                        type="checkbox"
                        onChange={() => props.onAllInclusiveChange(!props.showOnlyAllInclusive)}
                        checked={props.showOnlyAllInclusive}
                    />
                    <CheckboxLabel htmlFor="allInclusive">
                        {__('All inclusive', 'dreamlines')}
                    </CheckboxLabel>

                    <InfoIconWrapper onClick={() => setIsModalOpen(true)}>
                        <InfoIconStyled width={16} height={16} />
                    </InfoIconWrapper>
                </CheckboxWrapper>
            </FilterBox>
            {isModalOpen && (
                <InfoDialogStyled open onClose={() => setIsModalOpen(false)}>
                    <DialogHeader>
                        <DialogHeaderTitle>{__('All inclusive', 'dreamlines')}</DialogHeaderTitle>
                    </DialogHeader>
                    <DialogContent>
                        <DialogDescription>
                            {__('allInclusiveDescription', 'dreamlines')}
                        </DialogDescription>
                    </DialogContent>
                </InfoDialogStyled>
            )}
        </>
    );
};

export default PopularFilter;
