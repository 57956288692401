import { getHttpContext, IsomorphicHelpers } from '../../../../../helpers/IsomorphicHelpers';
import { IMobileSearchResultCruise } from '../../../../../interfaces/ISearchApi';

export const badgeTypes = {
    experience: 'experience',
    package: 'package',
} as const;

export const getSearchResultItemUrlWithQueryParams = (
    baseUrl,
    selectedSail,
    cabinKind,
    allInclusive,
) => {
    const isServerSide = IsomorphicHelpers.isServerSide;
    const httpContext = getHttpContext();

    const currentOrigin = isServerSide
        ? `${httpContext.request.protocol || 'https'}://${httpContext.request.headers.host}`
        : location.origin;

    const fullUrl = baseUrl.startsWith('http') ? baseUrl : `${currentOrigin}${baseUrl}`;
    const url = new URL(fullUrl);

    url.searchParams.set('selectedSail', selectedSail);
    url.searchParams.set('allInclusive', String(Boolean(allInclusive)));

    (cabinKind || []).forEach((id, index) => {
        url.searchParams.append(`filterParams[cabinKind][${index}]`, id.toString());
    });

    return url.toString();
};

export const getSearchResultCruiseImage = (
    cruiseData: IMobileSearchResultCruise,
    searchPageType: string,
) => {
    const { teaserImage, routeMapImage, shipImage, routeImageLarge } = cruiseData;

    if (teaserImage) {
        return teaserImage;
    }

    return (
        {
            map: routeMapImage,
            mapWithNoUGC: routeMapImage,
            ship: shipImage,
        }[searchPageType] ||
        shipImage ||
        routeImageLarge
    );
};
