import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { config } from '../../../../config';
import { BreakpointsEnum } from '../../../../helpers/breakpoints';
import { PackageLabelIcon } from '../../../../components/Icons/PackageLabelIcon';
import { InfoTransparentIcon } from '../../../../components/Icons/InfoTransparentIcon';
import { Modal } from '../../../../components/Modal';
import Link from '../../../../components/Link';
import { MarkAsFavorite } from '../../../../components/MarkAsFavorite';

export const DefaultBackground = css`
    background-image: url(${config.images}/common/dl_package_desktop_bg.svg);
`;

export const PackageLabelWrapper = styled.button<{ isCruise1st: boolean; isExperience?: boolean }>`
    height: 36px;
    border: 1px solid
        ${(props) => {
            if (props.isExperience) {
                return props.theme.colors.raspberry[100];
            }
            if (props.isCruise1st) {
                return props.theme.colors.yellowSea[100];
            }
            return props.theme.colors.azure[100];
        }};
    border-radius: ${({ theme }) => theme.container.borderRadius};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    ${({ isExperience, isCruise1st, theme }) => {
        if (isExperience) {
            return `background-color: ${theme.colors.magenta[100]};`;
        }
        if (isCruise1st) {
            return `background-color: ${theme.colors.yellowSea[100]}`;
        }
        return `background-color: ${theme.colors.azure[100]};`;
    }}
`;

export const PackageLabelText = styled.p<{ isCruise1st: boolean }>`
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: ${(props) =>
        props.isCruise1st ? props.theme.colors.cetaceanBlue[100] : props.theme.colors.white[100]};
    span {
        margin-right: 5px;
    }
`;

export const PackageLabelButton = styled.div<{ isExperience?: boolean; isCruise1st: boolean }>`
    display: flex;
    font-size: 14px;
    align-items: center;
    color: ${(props) =>
        props.isCruise1st ? props.theme.colors.cetaceanBlue[100] : props.theme.colors.white[100]};
    cursor: pointer;
    span {
        display: none;
    }
`;

export const PackageLabelIconStyled = styled(PackageLabelIcon)`
    margin: -1px 10px;
`;

export const InfoTransparentIconStyled = styled(InfoTransparentIcon)`
    margin: -1px 15px 0 10px;
`;

export const StyledInfoModal = styled(Modal)`
    .modalContent {
        max-width: 550px;
    }
`;

export const StyledOnBoardLabel = styled.p`
    background: ${(props) => props.theme.alert.succeed.background};
    border: 2px solid ${(props) => props.theme.alert.succeed.border};
    border-radius: ${({ theme }) => theme.container.borderRadius};
    padding: 6px 12px;
    font-size: 12px;
    font-weight: 700;
    margin: 0 16px 8px 16px;
    align-self: flex-start;
`;

export const StyledAdultOnlyCruiseLabel = styled.p`
    background: ${(props) => props.theme.alert.attention.background};
    border: 2px solid ${(props) => props.theme.alert.attention.border};
    border-radius: ${({ theme }) => theme.container.borderRadius};
    padding: 6px 12px;
    font-size: 12px;
    font-weight: 700;
    margin: 0 16px 8px 16px;
    align-self: flex-start;
`;

export const StyledInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    overflow: hidden;
`;

export const StyledSearchItemContent = styled(Link)<{ isPackage?: boolean }>`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: ${(props) => (props.isPackage ? '0 0 6px 6px' : '6px')};
    overflow: hidden;
    cursor: pointer;
    padding-bottom: 16px;

    &:hover {
        color: inherit;
    }
`;

export const ImageStyled = styled.img`
    object-fit: cover;
    height: inherit;
    width: inherit;

    &.routeImage {
        @media (min-width: ${BreakpointsEnum.xss}) {
            object-fit: fill;
        }
        @media (min-width: ${BreakpointsEnum.xs}) {
            object-fit: cover;
        }
    }
`;

export const CruiseImageContainer = styled.div<{ isPackage?: boolean }>`
    position: relative;
    flex: 0 0 50%;
    overflow: hidden;
    border-radius: ${(props) => (props.isPackage ? '0' : '6px 6px 0 0')};
    height: auto;

    @media (min-width: ${BreakpointsEnum.md}) {
        border-radius: ${({ theme }) => theme.container.borderRadius};
    }

    ${ImageStyled} {
        width: 100%;

        @media (min-width: ${BreakpointsEnum.md}) {
            border-radius: ${({ theme }) => theme.container.borderRadius};
        }
    }
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;

    @media (min-width: ${BreakpointsEnum.md}) {
        flex-direction: row;
        padding: 0 16px;

        ${CruiseImageContainer} {
            max-width: 50%;
            border-radius: ${({ theme }) => theme.container.borderRadius};
        }
    }
`;

export const StyledFavoriteBtn = styled(MarkAsFavorite)`
    position: absolute;
    right: 6px;
    top: 6px;

    @media (min-width: ${BreakpointsEnum.md}) {
        left: 12px;
        top: 12px;
        right: initial;
    }
`;
