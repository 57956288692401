import * as React from 'react';
import { connect } from 'react-redux';
import { ActiveTest } from '../../actions/abTestActions';
import {
    clearFilterParams,
    deleteFilterParam,
    doNewSearchViaUrl,
    setFilterParam,
    toggleSearchOverlay,
    toggleSearchFilter,
} from '../../actions/searchActions';
import * as Colors from '../../consts/variables';
import { __, numberFormatter } from '../../helpers/TranslationService';
import { deepClone } from '../../helpers/deepClone';
import { IPageDataReducer } from '../../reducers/pageData';
import { ISearchReducer, searchOverlayAction } from '../../reducers/search';
import { ILandingPageReducer } from '../../reducers/landingPage';
import { ActiveFilters } from './ActiveFilters';
import { DurationFilter } from './DurationFilter';
import { FilterFooter } from './FilterFooter';
import { FilterHeader } from './FilterHeader';
import { FilterInputForm } from './FilterInputForm';
import { PriceFilters } from './PriceFilter';
import { ResetButton } from './ResetButton';
import { RouteFilters } from './RouteFilters';
import { ShipFilters } from './ShipFilters';
import { overlayIndexes } from '../../theme/zIndex';
import CabinFilter from './CabinFilter';
import PopularFilter from './PopularFilter';

export const SEARCH_FILTERS_ID = 'search-filters-overlay';

const style: Record<string, React.CSSProperties> = {
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: overlayIndexes.searchFiltersMenu,
        background: Colors.white,
        overflow: 'auto',
    },
    wrapper: {
        padding: '0px 15px',
        marginBottom: '75px',
        overflow: 'hidden',
        background: Colors.lightGray,
    },
};

interface StateProps {
    landingPage: ILandingPageReducer;
    pageData: IPageDataReducer;
    abTests: { [key: string]: ActiveTest };
    search: ISearchReducer;
}

interface DispatchProps {
    setFilterParam(ICruiseFilterParamValue, doSearch?: boolean): () => any;
    toggleSearchFilter: () => void;
    doNewSearchViaUrl: () => void;
    deleteFilterParam(any): () => any;
    clearFilterParams: (fetchFacets: boolean, doSearch?: boolean) => () => void;
    loadFacets: Function;
    toggleSearchOverlay: (value: boolean, action: searchOverlayAction) => void;
}

type IProps = StateProps & DispatchProps & { isDesktop?: boolean };

const nonNumericFilters = ['country', 'endHarbourCountry', 'startHarbourCountry'];

function getInitialState(props: IProps, filterDefaults) {
    const state = deepClone(filterDefaults);
    const states = Object.keys(state);
    const deviceType = props?.pageData?.deviceType;
    const { filterParamsListActive } = props.search;
    states.forEach((val, key) => {
        let param = filterParamsListActive.filter((_) => _.paramName === val);
        if (param.length === 1) {
            if (Array.isArray(filterDefaults[val])) {
                state[val] = [Number(param.shift().paramValue)];
            } else {
                if (nonNumericFilters.includes(val)) {
                    state[val] = param.shift().paramValue;
                } else {
                    state[val] = Number(param.shift().paramValue);
                }
            }
        } else if (param.length > 1) {
            state[val] = param.map((filterParam) => {
                return Number(filterParam.paramValue);
            });
        }
    });

    if (deviceType === 'desktop') {
        state.dateMin =
            (state?.dateMin + '').length === 10 ? parseInt(state.dateMin, 10) : state?.dateMin;
        state.dateMax =
            (state?.dateMax + '').length === 10 ? parseInt(state.dateMax, 10) : state?.dateMax;
    }

    return state;
}

const CABIN_FILTER_PARAM_NAME = 'cabinKind';

const getActiveCabinTypes = (filterParamsListActive, filterParamValue) => {
    return filterParamsListActive?.reduce((activeFilters, element) => {
        if (
            element.paramName === CABIN_FILTER_PARAM_NAME &&
            element.paramValue !== filterParamValue
        ) {
            activeFilters.push(element.paramValue);
        }
        return activeFilters;
    }, []);
};

export class SearchFiltersClass extends React.Component<IProps, any> {
    constructor(props) {
        super(props);
        this.state = getInitialState(this.props, this.filterDefaults);
    }

    filterDefaults = {
        dateMax: null,
        dateMin: null,
        zone: 'default',
        company: 'default',
        ship: 'default',
        startHarbour: 'default',
        endHarbour: 'default',
        harbour: 'default',
        startHarbourCountry: 'default',
        endHarbourCountry: 'default',
        country: 'default',
        discount: 0,
        inclFlight: false,
        priceMin: this.props.pageData.brandConfig.priceMin || 0,
        priceMax: this.props.search?.searchResults.maxPrice || 10000,
        shipType: this.props.pageData.brandConfig.shipType?.sea.concat(
            this.props.pageData.brandConfig.shipType.river,
        ),
        shipSizeMin: 0,
        shipSizeMax: 0,
        shipCategory: [],
        cabinKind: [],
        banderoles: 'default',
        nightsMin: 0,
        nightsMax: null,
        showOnlyAllInclusive: false,
    };

    resetParam = (stateKey) => {
        const start = ['startHarbourCountry', 'startHarbour'];
        const end = ['endHarbourCountry', 'endHarbour'];
        const stop = ['country', 'harbour'];

        const resetState = (key) => {
            this.props.deleteFilterParam(key);
            this.setState((state) => {
                let newState = {};
                newState[key] = 'default';
                const nextState = { ...state, ...newState };
                return nextState;
            });
        };

        switch (stateKey) {
            case 'startHarbourCountry':
            case 'startHarbour':
                start.map((key) => resetState(key));
                return;
            case 'country':
            case 'harbour':
                stop.map((key) => resetState(key));
                return;
            case 'endHarbourCountry':
            case 'endHarbour':
                end.map((key) => resetState(key));
                return;
            default:
                return;
        }
    };

    setFilter = (stateKey: string, isFilterInitialized: boolean = true) => {
        return (param: any) => {
            this.resetParam(stateKey);
            if (!isFilterInitialized) {
                this.props.toggleSearchOverlay(true, null);
            }

            this.setState(
                (state) => {
                    let newState = {};
                    newState[stateKey] = param;
                    const nextState = { ...state, ...newState };
                    return nextState;
                },
                () => this.props.setFilterParam([{ paramName: stateKey, paramValue: param }]),
            );
        };
    };

    setFilterArray = (stateKeys: string[], isFilterInitialized: boolean = true) => {
        return (params: any[]) => {
            if (!isFilterInitialized) {
                this.props.toggleSearchOverlay(true, null);
            }
            let newState = {};
            const newParams = [];
            stateKeys.forEach((stateKey, index) => {
                const param = params[index];
                newState[stateKey] = param;
                newParams.push({ paramName: stateKey, paramValue: param });
            });
            const nextState = { ...this.state, ...newState };
            this.setState(nextState, () => this.props.setFilterParam(newParams));
        };
    };
    clearFilterParams = () => {
        return () => {
            this.setState(this.filterDefaults);
            this.props.clearFilterParams(true, true);
        };
    };

    removeActiveFilter = (filterParam: any) => {
        if (filterParam.paramName === CABIN_FILTER_PARAM_NAME) {
            const cabinKindActiveFilters = getActiveCabinTypes(
                this.props.search?.filterParamsListActive,
                filterParam.paramValue,
            );
            this.props.setFilterParam(
                [{ paramName: filterParam.paramName, paramValue: cabinKindActiveFilters }],
                true,
            );
        } else {
            this.setState({ [filterParam.paramName]: this.filterDefaults[filterParam.paramName] });
            this.props.setFilterParam(
                [{ paramName: filterParam.paramName, paramValue: null }],
                true,
            );
        }
    };

    componentDidMount() {
        if (this.props?.pageData?.deviceType === 'desktop') {
            return;
        }
        document.body.classList.add('search-filters-open');
    }

    componentDidUpdate(prevProps: IProps) {
        const isSearchOverlayClosed =
            !this.props.search.isSeachListHidden &&
            this.props.search.searchOverlayAction === 'cancel' &&
            prevProps.search.isSeachListHidden !== this.props.search.isSeachListHidden;

        if (isSearchOverlayClosed) {
            const initState = getInitialState(this.props, this.filterDefaults);

            this.setState({ ...this.state, ...initState });
        }
    }

    componentWillUnmount() {
        if (this.props?.pageData?.deviceType === 'desktop') {
            return;
        }
        document.body.classList.remove('search-filters-open');
    }

    render() {
        const { search, setFilterParam, pageData, isDesktop } = this.props;
        const {
            dateMax,
            dateMin,
            zone,
            company,
            ship,
            startHarbour,
            endHarbour,
            harbour,
            startHarbourCountry,
            endHarbourCountry,
            country,
            priceMin,
            priceMax,
            discount,
            inclFlight,
            shipType,
            shipSizeMin,
            shipSizeMax,
            shipCategory,
            cabinKind,
            banderoles,
            nightsMin,
            nightsMax,
            showOnlyAllInclusive,
        } = this.state;
        const { defaultCurrency, dualCurrency } = pageData.appConfig;
        const { deviceType } = pageData;

        const filterInputProps = {
            pageData: this.props.pageData,
            search,
            setFilterParam,
            dateMax,
            dateMin,
            zone,
            company,
            inclFlight,
            shipType,
            nightsMin,
            nightsMax,
            onCompanyChange: this.setFilter('company', false),
            onZoneChange: this.setFilter('zone', false),
            onDateMinChange: this.setFilter('dateMin', false),
            onDateMaxChange: this.setFilter('dateMax', false),
            onFlightChange: this.setFilter('inclFlight', false),
            onShipTypeChange: this.setFilter('shipType', false),
            onDateChange: this.setFilterArray(['dateMin', 'dateMax'], false),
            onDurationChange: this.setFilterArray(['nightsMin', 'nightsMax'], false),
            forceDirection: (isDesktop ? 'down' : undefined) as any,
            autoSetFocus: isDesktop,
        };

        const shipProps = {
            search,
            setFilterParam,
            ship,
            banderoles,
            onShipChange: this.setFilter('ship', false),
            shipSizeMin,
            shipSizeMax,
            onShipSizeChange: this.setFilterArray(['shipSizeMin', 'shipSizeMax'], false),
            shipCategory,
            onShipCategoryChange: this.setFilter('shipCategory', false),
            onBanderoleChange: this.setFilter('banderoles', false),
            autoSetFocus: isDesktop,
        };

        const cabinProps = {
            cabinKind,
            onCabinTypeChange: this.setFilter('cabinKind', false),
        };

        const popularProps = {
            showOnlyAllInclusive,
            onAllInclusiveChange: this.setFilter('showOnlyAllInclusive', false),
        };

        const routeProps = {
            search,
            startHarbour,
            endHarbour,
            stopHarbour: harbour,
            startHarbourCountry,
            endHarbourCountry,
            stopHarbourCountry: country,
            onStartHarbourChange: this.setFilter('startHarbour', false),
            onEndHarbourChange: this.setFilter('endHarbour', false),
            onHarbourChange: this.setFilter('harbour', false),
            onStartCountryChange: this.setFilter('startHarbourCountry', false),
            onEndCountryChange: this.setFilter('endHarbourCountry', false),
            onCountryChange: this.setFilter('country', false),
            autoSetFocus: isDesktop,
        };

        const priceProps = {
            search,
            priceMin,
            priceMax,
            discount,
            onDiscountChange: this.setFilter('discount', false),
            onPriceChange: this.setFilterArray(['priceMin', 'priceMax'], false),
            isMobile: this.props?.pageData?.deviceType === 'mobile',
            currency: defaultCurrency,
            dualCurrency: dualCurrency,
            exchangeRate: pageData.header.exchangeRate
                ? pageData.header.exchangeRate.exchangeRate
                : null,
        };
        const getNightsMax =
            this.props.landingPage.searchResult.maxNights ||
            this.props.search.searchResults.maxNights;

        const durationProps = {
            search,
            nightsMin: nightsMin || 0,
            nightsMax: nightsMax || getNightsMax,
            onDurationChange: this.setFilterArray(['nightsMin', 'nightsMax'], false),
            maxNightsRange: getNightsMax || 300,
            isMobile: this.props?.pageData?.deviceType === 'mobile',
        };
        const numResults = search.searchFacets.numResults;
        const disableSearchButton = numResults ? false : true;

        const footerProps = {
            title:
                (Number.isInteger(numResults) ? numberFormatter().format(numResults) + ' ' : '') +
                __('use filters', 'dreamlines'),
            toggleSearchFilter: this.props.toggleSearchFilter,
            doNewSearchViaUrl: this.props.doNewSearchViaUrl,
            isLoading: this.props.search.isFacetsLoading,
            isDisabled: disableSearchButton,
        };

        const activeFilterProps = {
            search,
            removeFilter: this.removeActiveFilter,
            clearFilterParams: this.clearFilterParams(),
            currency: defaultCurrency,
            dualCurrency: dualCurrency,
            exchangeRate: pageData.header.exchangeRate
                ? pageData.header.exchangeRate.exchangeRate
                : null,
        };
        return (
            <div style={style.container} id={SEARCH_FILTERS_ID} className="searchFilterContainer">
                {!isDesktop && (
                    <FilterHeader
                        clearFilterParams={this.clearFilterParams()}
                        toggleSearchFilter={this.props.toggleSearchFilter}
                    />
                )}
                <div style={style.wrapper} className="searchFilterWrapper">
                    {!isDesktop && <ActiveFilters {...activeFilterProps} />}
                    <FilterInputForm
                        {...filterInputProps}
                        footerProps={footerProps}
                        deviceType={deviceType}
                        clearFilterParams={this.clearFilterParams()}
                    />
                    <PopularFilter {...popularProps} />
                    <CabinFilter {...cabinProps} />
                    <DurationFilter {...durationProps} />
                    <PriceFilters {...priceProps} />
                    <RouteFilters {...routeProps} />
                    <ShipFilters {...shipProps} />
                    {isDesktop && (
                        <>
                            <ResetButton onClick={this.clearFilterParams()} />
                        </>
                    )}
                </div>
                {!isDesktop && (
                    <FilterFooter {...footerProps} testSelectorName="search-filter-request" />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: StateProps) => ({
    landingPage: state.landingPage,
    pageData: state.pageData,
    abTests: state.abTests,
    search: state.search,
});

const mapDispatchToProps = {
    setFilterParam,
    deleteFilterParam,
    clearFilterParams,
    toggleSearchFilter,
    doNewSearchViaUrl,
    toggleSearchOverlay,
};

// @TODO: Fix dispatch props
export const SearchFilters = connect<StateProps, any, any>(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
)(SearchFiltersClass);
