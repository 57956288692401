import * as React from 'react';
import { IIcon } from '../../interfaces/IIcon';
import { Icon } from '../Icon';

export function ParkingSpaceIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox || '0 0 24 24'}
            style={props.style}
            className={props.className}
        >
            <g>
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.13585 0H21.8642C23.0389 0 24 0.961123 24 2.13583V21.8642C24 23.0389 23.0389 24 21.8642 24H2.13585C0.961123 24 0 23.0389 0 21.8642V2.13583C0 0.961122 0.961123 0 2.13585 0ZM16.4872 12.9729C17.0334 12.495 17.4473 11.9062 17.7289 11.2022C18.0148 10.5023 18.1556 9.70016 18.1556 8.78703C18.1556 8.12991 18.066 7.53685 17.8826 7.00775C17.699 6.47436 17.4303 6.0136 17.0804 5.6168C16.7304 5.2199 16.3037 4.89134 15.8089 4.63538C15.3138 4.37504 14.8232 4.20006 14.341 4.10199C13.8546 4.00812 13.4407 3.9441 13.0951 3.91422C12.7494 3.8844 12.3825 3.87154 11.9985 3.87154H8.1838C7.85101 3.87154 7.5822 3.9697 7.37737 4.16592C7.17257 4.36221 7.07014 4.65663 7.07014 5.04927V19.6037C7.07014 19.6848 7.09575 19.7616 7.14694 19.8298C7.19389 19.8938 7.28346 19.9493 7.40719 19.992C7.53096 20.0304 7.69735 20.0645 7.91071 20.0901C8.12404 20.1157 8.38861 20.1285 8.70436 20.1285C9.02438 20.1285 9.29322 20.1157 9.498 20.0901C9.70709 20.0645 9.87348 20.0346 9.99727 19.992C10.121 19.9493 10.2106 19.8938 10.2661 19.8298C10.3215 19.7616 10.3471 19.689 10.3471 19.6037V14.445H11.6997C12.7707 14.445 13.7009 14.3213 14.4903 14.0694C15.2797 13.8177 15.9453 13.455 16.4872 12.9729ZM12.758 6.47411C13.078 6.52108 13.3852 6.63201 13.6796 6.80702C13.9741 6.9819 14.2215 7.25504 14.4221 7.6219C14.6226 7.98894 14.725 8.45819 14.725 9.02575C14.725 9.44398 14.6653 9.83224 14.5501 10.1864C14.4307 10.5405 14.2599 10.8477 14.0338 11.0995C13.8076 11.3555 13.5133 11.5561 13.1591 11.6968C12.8007 11.8377 12.3612 11.9102 11.8321 11.9102H10.3472V6.40583H11.7553C12.1009 6.40583 12.4337 6.42727 12.758 6.47411Z"
                    fill="currentFill"
                />
            </g>
        </Icon>
    );
}
