import React from 'react';
import styled from '@emotion/styled';
import { __ } from '../../../../helpers/TranslationService';
import * as InclusivesIcons from '../../helper/InclusiveIcons';
import {
    PackageLabelWrapper,
    PackageLabelText,
    PackageLabelIconStyled,
    PackageLabelButton,
    InfoTransparentIconStyled,
} from './styles';
import { badgeTypes } from './helpers/search-helpers';

interface IProps {
    iconProps: {
        width: number;
        height: number;
    };
    handleOnClickBadge: (e: React.MouseEvent) => void;
    typeOfBadge: string;
    experienceTitle?: string;
    icons: { icon: string; text: string }[];
    isCruise1st?: boolean;
}

const StyledContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0 0 0;
    margin: 0 16px 16px 16px;
    gap: 4px;
    position: relative;
`;

const FeaturePill = styled.p`
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.background.layerSecondary};
    border: 2px solid ${(props) => props.theme.border.onLayer};
    border-radius: 8px;
    padding: 8px;

    span {
        margin-left: 4px;
    }
`;

// Defined order as per DREAM-17751
// Cruise, Flight, Additional Flight, Train Ride,Hotel, Tour, Cruise Tour, Bicycle Tour, All Inclusive, Free Wifi, Drinks, Full Board, Half Board, Tips, Children Travel Free, Breakfest

const INCLUSIVES_ORDER = [
    'CruiseIcon',
    'FlightIcon',
    'FlightExtraIcon',
    'TrainIcon',
    'HotelIcon',
    'TourIcon',
    'BicycleTourIcon',
    'AllInclusiveIcon',
    'FreeWiFiIcon',
    'WineIcon',
    'FullBoardIcon',
    'MoneyIcon',
    'KidsIcon',
    'BreakfastIcon',
    'FeatureOnBoardCreditIcon',
    'FeatureParkingSpaceIcon',
];

export const SearchResultItemFeatures = ({
    icons,
    iconProps,
    typeOfBadge,
    handleOnClickBadge,
    experienceTitle,
    isCruise1st,
}: IProps) => {
    const inclusives = React.useMemo(
        () =>
            [{ icon: 'CruiseIcon', text: 'cruiseData.cruiseVal' }, ...icons]
                ?.filter((item) => INCLUSIVES_ORDER.includes(item?.icon) && item?.icon)
                ?.sort((a, b) => {
                    const indexA = INCLUSIVES_ORDER.indexOf(a.icon);
                    const indexB = INCLUSIVES_ORDER.indexOf(b.icon);

                    if (indexA !== -1 && indexB !== -1) return indexA - indexB;
                    if (indexA !== -1) return -1;
                    if (indexB !== -1) return 1;
                    return 0;
                }),
        [icons],
    );

    const isExperienceBadgeType = typeOfBadge === badgeTypes.experience;

    return (
        <StyledContainer>
            {!!typeOfBadge && (
                <PackageLabelWrapper
                    onClick={handleOnClickBadge}
                    isExperience={isExperienceBadgeType}
                    isCruise1st={isCruise1st}
                >
                    <PackageLabelText isCruise1st={isCruise1st}>
                        <PackageLabelIconStyled {...iconProps} />
                        {isExperienceBadgeType
                            ? experienceTitle
                            : __('Dreamlines Package', 'dreamlines')}
                    </PackageLabelText>
                    <PackageLabelButton isCruise1st={isCruise1st}>
                        <InfoTransparentIconStyled width={12} height={12} {...iconProps} />
                    </PackageLabelButton>
                </PackageLabelWrapper>
            )}
            {inclusives?.map((item) => {
                const IconComp = InclusivesIcons[item?.icon];
                return (
                    <FeaturePill key={item?.icon}>
                        {IconComp && <IconComp width={12} height={12} />}
                        <span>{__(item?.text, 'dreamlines')}</span>
                    </FeaturePill>
                );
            })}
        </StyledContainer>
    );
};
